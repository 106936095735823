<template>
  <DashboardAdminTemplate>
    <div v-loading="loading" class="pd-x-1" style="min-height: 840px">
      <h1 v-if="assessment == ''">
        {{ type == "answer" ? "เพิ่มเกณฑ์" : "เพิ่มคำแนะนำ" }}
      </h1>
      <h1 v-else>
        {{ type == "answer" ? "แก้ไขเกณฑ์การประเมิน" : "แก้ไขคำแนะนำ" }}
      </h1>
      <el-form
        :model="form"
        :rules="rules"
        label-position="left"
        label-width="180px"
        ref="form"
      >
        <el-form-item label="ขนาดของธุรกิจ" prop="assessmentId">
          <el-select
            v-model="form.assessmentId"
            placeholder="กรุณาเลือก"
            v-if="assessment == ''"
          >
            <el-option
              v-for="item in assessmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span class="text-primary" v-else>
            <span v-if="assessmentList.length > 0">
              {{ fucFilter(assessmentList, "id", form.assessmentId, "name") }}
            </span>
          </span>
        </el-form-item>
        <el-form-item label="ด้านประเมินความพร้อม" prop="cateId">
          <el-select
            v-model="form.cateId"
            placeholder="กรุณาเลือก"
            v-if="assessment == ''"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span class="text-primary" v-else>
            {{ fucFilter(categoryList, "id", form.cateId, "name") }}
          </span>
        </el-form-item>
        <el-form-item label="ปัจจัยประเมินความพร้อม" prop="questionId">
          <el-select
            v-model="form.questionId"
            placeholder="กรุณาเลือก"
            v-if="assessment == ''"
          >
            <el-option
              v-for="item in questionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span class="text-primary" v-else>
            {{ fucFilter(questionList, "id", form.questionId, "name") }}
          </span>
        </el-form-item>
        <div v-if="resetEditor">
          <div v-if="config && !loading">
            <h4>ระดับ 1</h4>
            <div v-if="type == 'suggestion'">
              <h5>ข้อความแบบสั้น</h5>
              <!-- <editor
                :ref="`tuiEditorShort1`"
                :options="editerOptions"
                :initialValue="short1"
                height="180px"
                initialEditType="wysiwyg"
              /> -->
              <vue-editor :config="config" v-model="short1"></vue-editor>
            </div>
            <div v-if="config && !loading">
              <h5>
                {{ type == "suggestion" ? "ข้อความแบบยาว" : "คำอธิบาย" }}
              </h5>
              <!-- <editor
                :ref="`tuiEditorExpane1`"
                :options="editerOptions"
                :initialValue="expane1"
                height="180px"
                initialEditType="wysiwyg"
              /> -->
              <vue-editor :config="config" v-model="expane1"></vue-editor>
            </div>
            <div v-if="type == 'suggestion'">
              <div class="is-flex js-between ai-center border-bottom pd-7">
                <h4>เครื่องมือ</h4>
                <div>
                  <el-button
                    @click="openDailogAddon('tool', 1)"
                    type="info"
                    size="mini"
                    >+ เพิ่มเครื่องมือ</el-button
                  >
                </div>
              </div>
              <div
                v-for="(item, index) in tool.item1"
                :key="index"
                class="is-flex mg-y-5"
              >
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
                <div class="mg-l-5">
                  <i
                    @click="openDailogAddon('tool', 1, item, index, 'edit')"
                    class="el-icon-edit mg-r-7"
                  ></i>
                  <i
                    @click="delAddon(index, 1, 'tool')"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="type == 'suggestion'">
              <div class="is-flex js-between ai-center border-bottom pd-7">
                <h4>หลักสูตร</h4>
                <div>
                  <el-button
                    @click="openDailogAddon('course', 1)"
                    type="info"
                    size="mini"
                    >+ เพิ่มหลักสูตร</el-button
                  >
                </div>
              </div>
              <div
                v-for="(item, index) in course.item1"
                :key="index"
                class="is-flex mg-y-5"
              >
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
                <div class="mg-l-5">
                  <i
                    @click="openDailogAddon('course', 1, item, index, 'edit')"
                    class="el-icon-edit mg-r-7"
                  ></i>
                  <i
                    @click="delAddon(index, 1, 'course')"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div v-if="config && !loading">
            <h4 class="mg-t-1">ระดับ 2</h4>
            <div v-if="type == 'suggestion'">
              <h5>ข้อความแบบสั้น</h5>
              <!-- <editor
                :ref="`tuiEditorShort2`"
                :options="editerOptions"
                :initialValue="short2"
                height="180px"
                initialEditType="wysiwyg"
              /> -->
              <vue-editor :config="config" v-model="short2"></vue-editor>
            </div>
            <div v-if="config && !loading">
              <h5>
                {{ type == "suggestion" ? "ข้อความแบบยาว" : "คำอธิบาย" }}
              </h5>
              <!-- <editor
                :ref="`tuiEditorExpane2`"
                :options="editerOptions"
                :initialValue="expane2"
                height="180px"
                initialEditType="wysiwyg"
              /> -->
              <vue-editor :config="config" v-model="expane2"></vue-editor>
            </div>
            <div v-if="type == 'suggestion'">
              <div class="is-flex js-between ai-center border-bottom pd-7">
                <h4>เครื่องมือ</h4>
                <div>
                  <el-button
                    @click="openDailogAddon('tool', 2)"
                    type="info"
                    size="mini"
                    >+ เพิ่มเครื่องมือ</el-button
                  >
                </div>
              </div>
              <div
                v-for="(item, index) in tool.item2"
                :key="index"
                class="is-flex mg-y-5"
              >
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
                <div class="mg-l-5">
                  <i
                    @click="
                      openDailogAddon('tool', 2, item, index, 'edit', 'edit')
                    "
                    class="el-icon-edit mg-r-7"
                  ></i>
                  <i
                    @click="delAddon(index, 2, 'tool')"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="type == 'suggestion'">
              <div class="is-flex js-between ai-center border-bottom pd-7">
                <h4>หลักสูตร</h4>
                <div>
                  <el-button
                    @click="openDailogAddon('course', 2)"
                    type="info"
                    size="mini"
                    >+ เพิ่มหลักสูตร</el-button
                  >
                </div>
              </div>
              <div
                v-for="(item, index) in course.item2"
                :key="index"
                class="is-flex mg-y-5"
              >
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
                <div class="mg-l-5">
                  <i
                    @click="openDailogAddon('course', 2, item, index, 'edit')"
                    class="el-icon-edit mg-r-7"
                  ></i>
                  <i
                    @click="delAddon(index, 2, 'course')"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div v-if="config && !loading">
            <h4 class="mg-t-1">ระดับ 3</h4>
            <div v-if="type == 'suggestion'">
              <h5>ข้อความแบบสั้น</h5>
              <!-- <editor
                :ref="`tuiEditorShort3`"
                :options="editerOptions"
                :initialValue="short3"
                height="180px"
                initialEditType="wysiwyg"
              /> -->
              <vue-editor :config="config" v-model="short3"></vue-editor>
            </div>
            <div v-if="config && !loading">
              <h5>
                {{ type == "suggestion" ? "ข้อความแบบยาว" : "คำอธิบาย" }}
              </h5>
              <!-- <editor
                :ref="`tuiEditorExpane3`"
                :options="editerOptions"
                :initialValue="expane3"
                height="180px"
                initialEditType="wysiwyg"
              /> -->
              <vue-editor :config="config" v-model="expane3"></vue-editor>
            </div>
            <div v-if="type == 'suggestion'">
              <div class="is-flex js-between ai-center border-bottom pd-7">
                <h4>เครื่องมือ</h4>
                <div>
                  <el-button
                    @click="openDailogAddon('tool', 3)"
                    type="info"
                    size="mini"
                    >+ เพิ่มเครื่องมือ</el-button
                  >
                </div>
              </div>
              <div
                v-for="(item, index) in tool.item3"
                :key="index"
                class="is-flex mg-y-5"
              >
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
                <div class="mg-l-5">
                  <i
                    @click="openDailogAddon('tool', 3, item, index, 'edit')"
                    class="el-icon-edit mg-r-7"
                  ></i>
                  <i
                    @click="delAddon(index, 3, 'tool')"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="type == 'suggestion'">
              <div class="is-flex js-between ai-center border-bottom pd-7">
                <h4>หลักสูตร</h4>
                <div>
                  <el-button
                    @click="openDailogAddon('course', 3)"
                    type="info"
                    size="mini"
                    >+ เพิ่มหลักสูตร</el-button
                  >
                </div>
              </div>
              <div
                v-for="(item, index) in course.item3"
                :key="index"
                class="is-flex mg-y-5"
              >
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
                <div class="mg-l-5">
                  <i
                    @click="openDailogAddon('course', 3, item, index, 'edit')"
                    class="el-icon-edit mg-r-7"
                  ></i>
                  <i
                    @click="delAddon(index, 3, 'course')"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div v-if="config && !loading">
            <h4 class="mg-t-1">ระดับ 4</h4>
            <div v-if="type == 'suggestion'">
              <h5>ข้อความแบบสั้น</h5>
              <!-- <editor
                :ref="`tuiEditorShort4`"
                :options="editerOptions"
                :initialValue="short4"
                height="180px"
                initialEditType="wysiwyg"
              /> -->
              <vue-editor :config="config" v-model="short4"></vue-editor>
            </div>
            <div v-if="config && !loading">
              <h5>
                {{ type == "suggestion" ? "ข้อความแบบยาว" : "คำอธิบาย" }}
              </h5>
              <!-- <editor
                :ref="`tuiEditorExpane4`"
                :options="editerOptions"
                :initialValue="expane4"
                height="180px"
                initialEditType="wysiwyg"
              /> -->
              <vue-editor :config="config" v-model="expane4"></vue-editor>
            </div>

            <div v-if="type == 'suggestion'">
              <div class="is-flex js-between ai-center border-bottom pd-7">
                <h4>เครื่องมือ</h4>
                <div>
                  <el-button
                    @click="openDailogAddon('tool', 4)"
                    type="info"
                    size="mini"
                    >+ เพิ่มเครื่องมือ</el-button
                  >
                </div>
              </div>
              <div
                v-for="(item, index) in tool.item4"
                :key="index"
                class="is-flex mg-y-5"
              >
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
                <div class="mg-l-5">
                  <i
                    @click="openDailogAddon('tool', 4, item, index, 'edit')"
                    class="el-icon-edit mg-r-7"
                  ></i>
                  <i
                    @click="delAddon(index, 4, 'tool')"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>

            <div v-if="type == 'suggestion'">
              <div class="is-flex js-between ai-center border-bottom pd-7">
                <h4>หลักสูตร</h4>
                <div>
                  <el-button
                    @click="openDailogAddon('course', 4)"
                    type="info"
                    size="mini"
                    >+ เพิ่มหลักสูตร</el-button
                  >
                </div>
              </div>
              <div
                v-for="(item, index) in course.item4"
                :key="index"
                class="is-flex mg-y-5"
              >
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
                <div class="mg-l-5">
                  <i
                    @click="openDailogAddon('course', 4, item, index, 'edit')"
                    class="el-icon-edit mg-r-7"
                  ></i>
                  <i
                    @click="delAddon(index, 4, 'course')"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div class="text-center pd-y-5">
        <el-button
          v-if="type == 'answer'"
          @click="status == 'create' ? createAnswer() : updateAnswer()"
          type="primary"
          >{{ status == "create" ? "เพิ่ม" : "บันทึก" }}</el-button
        >
        <el-button
          v-if="type == 'suggestion'"
          @click="status == 'create' ? createSuggestion() : updateSuggestion()"
          type="primary"
          >{{ status == "create" ? "เพิ่ม" : "บันทึก" }}</el-button
        >
      </div>
    </div>

    <el-dialog
      :close="closeDialog()"
      :visible.sync="dialogFormAddOn"
      :custom-class="'answerDialog'"
    >
      <span slot="title" class="font-22">
        {{
          typeAddon == "course" && status == "create"
            ? "เพิ่มหลักสูตร"
            : typeAddon == "tool" && status == "create"
            ? "เพิ่มเครื่องมือ"
            : typeAddon == "course"
            ? "แก้ไขหลักสูตร"
            : "แก้ไขเครื่องมือ"
        }}</span
      >
      <el-form :model="formAddOn">
        <el-form-item
          :label="typeAddon == 'course' ? 'หลักสูตร' : 'เครื่องมือ'"
        >
          <el-input v-model="formAddOn.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="typeAddon == 'course' ? 'Link หลักสูตร' : 'Link เครื่องมือ'"
        >
          <el-input
            v-model="formAddOn.url"
            placeholder="Ex. https://www.exim.go.th/"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="wantCreate" type="primary" @click="createAddon">{{
          typeAddon == "course" ? "เพิ่มหลักสูตร" : "เพิ่มเครื่องมือ"
        }}</el-button>
        <el-button v-else type="primary" @click="updateAddon">บันทึก</el-button>
      </span>
    </el-dialog>
  </DashboardAdminTemplate>
</template>
<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import vueEditor from "yimo-vue-editor";
import { HTTP } from "@/service/axios";

export default {
  props: {
    routeParams: {
      type: Object,
      default: null
    }
  },
  components: {
    DashboardAdminTemplate,
    vueEditor
  },
  computed: {
    assessmentList() {
      return this.$store.state.assessmentList;
    },
    categoryList() {
      return this.$store.state.categoryList;
    },
    questionList() {
      return this.$store.state.questionList;
    },
    token() {
      return this.$store.state.token;
    }
  },
  watch: {
    routeParams() {
      this.setParams();
    }
  },
  data() {
    return {
      config: {
        name: "vue-editor",
        // uploadImgUrl:process.env.VUE_APP_BASE_URL_API+'upload/imgmsg', // upload api
        printLog: false, // disabled console.log
        // lang: YimoVueEditor.E.langs.en, // lang config
        // pasteText: true,
        // pasteFilter: true,
        // hideLinkImg: true,
        colors: {
          "#000000": "Black",
          "#6E6E6E": "Drak Grey",
          "#909399": "Grey",
          "#fffba1": "Highlight Yellow",
          "#00C88C": "Lime",
          "#ffb800": "Yellow",
          "#07509C": "EXIM Blue",
          "#E92127": "EXIM Red"
        },
        lang: {
          bold: "Bold",
          underline: "Underline",
          italic: "Italic",
          forecolor: "Font color",
          bgcolor: "Background color",
          strikethrough: "Strikethrough",
          eraser: "Eraser",
          source: "Codeview",
          quote: "Quote",
          fontfamily: "Font family",
          fontsize: "Font size",
          head: "Head",
          orderlist: "Ordered list",
          unorderlist: "Unordered list",
          alignleft: "Align left",
          aligncenter: "Align center",
          alignright: "Align right",
          link: "Insert link",
          linkTarget: "Open mode",
          text: "Text",
          submit: "Submit",
          cancel: "Cancel",
          unlink: "Unlink",
          table: "Table",
          emotion: "Emotions",
          img: "Image",
          uploadImg: "Upload",
          linkImg: "Link",
          video: "Video",
          width: "width",
          height: "height",
          location: "Location",
          loading: "Loading",
          searchlocation: "search",
          dynamicMap: "Dynamic",
          clearLocation: "Clear",
          langDynamicOneLocation: "Only one location in dynamic map",
          insertcode: "Insert Code",
          undo: "Undo",
          redo: "Redo",
          fullscreen: "Full screnn",
          openLink: "open link",
          uploadPlaceTxt: "uploading__",
          uploadTimeoutPlaceTxt: "upload_timeout__",
          uploadErrorPlaceTxt: "upload_error__"
        },
        codeDefaultLang: "javascript",
        menus: [
          "source",
          "|",
          "bold",
          "underline",
          "italic",
          "strikethrough",
          "eraser",
          "forecolor",
          "bgcolor",
          "|",
          "quote",
          // 'fontfamily',
          "fontsize",
          // 'head',
          "unorderlist",
          "orderlist",
          "alignleft",
          "aligncenter",
          "alignright",
          "|",
          "link",
          "unlink",
          "table",
          // 'emotion',
          "|",
          "img",
          // 'video',
          // 'insertcode',
          "|",
          "undo",
          "redo",
          "fullscreen"
        ]
      },
      form: {
        assessmentId: "",
        cateId: "",
        questionId: ""
      },
      rules: {
        assessmentId: [
          { required: true, message: "กรุณาเลือก", trigger: "change" }
        ],
        cateId: [{ required: true, message: "กรุณาเลือก", trigger: "change" }],
        questionId: [
          { required: true, message: "กรุณาเลือก", trigger: "change" }
        ]
      },
      // editerOptions: {
      //   linkAttribute: {
      //     target: "_blank",
      //   },
      //   hideModeSwitch: true,
      //   toolbarItems: [
      //     "heading",
      //     "bold",
      //     "italic",
      //     "strike",
      //     "divider",
      //     "hr",
      //     "quote",
      //     "divider",
      //     "ul",
      //     "ol",
      //     "indent",
      //     "outdent",
      //     "divider",
      //     "table",
      //     "link",
      //     "divider",
      //     "code",
      //     "codeblock",
      //     "colour",
      //   ],
      // },
      loading: true,
      status: "",
      type: "",
      resetEditor: true,
      assessment: "",
      cate: "",
      question: "",
      short1: "",
      short2: "",
      short3: "",
      short4: "",
      expane1: "",
      expane2: "",
      expane3: "",
      expane4: "",
      qAndC: [],
      choices: [],
      recommend: [],
      dialogFormAddOn: false,
      typeAddon: "course",
      formAddOn: {
        name: "",
        url: ""
      },
      tool: {
        item1: [],
        item2: [],
        item3: [],
        item4: []
      },
      course: {
        item1: [],
        item2: [],
        item3: [],
        item4: []
      },
      numAddOn: "",
      wantCreate: true,
      indexAddon: 0,
      editTool: {
        item1: [],
        item2: [],
        item3: [],
        item4: []
      },
      editCourse: {
        item1: [],
        item2: [],
        item3: [],
        item4: []
      }
    };
  },
  async created() {
    this.getAssessment();
    this.getCategory();
    this.getQuestion();
  },
  mounted() {
    this.setParams();
  },
  methods: {
    closeDialog() {
      this.status = "edit";
    },
    getAssessment ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`assessment`)
        .then(res => {
          if (res.data.success) {
            this.$store.commit("SET_ASSESSMENT_LIST", res.data.obj);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_ASSESSMENT_LIST error", e.response);
        });
    },
    getCategory ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`category`)
        .then(res => {
          if (res.data.success) {
            this.$store.commit("SET_CATEGORY_LIST", res.data.obj);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_CATEGORY_LIST error", e.response);
        });
    },
    getQuestion ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`question`)
        .then(res => {
          if (res.data.success) {
            this.$store.commit("SET_QUESTION_LIST", res.data.obj);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_QUESTION_LIST error", e.response);
        });
    },
    fucFilter(data, param1 = "value", param2, valuereturn = "label") {
      let obj = data.filter(a => a[param1] == param2);
      if (obj.length > 0) {
        return obj[0][valuereturn];
      } else {
        return "";
      }
    },
    setParams() {
      if (this.routeParams != null) {
        if (typeof this.routeParams.status != "undefined") {
          this.status = this.routeParams.status;
        }

        if (typeof this.routeParams.type != "undefined") {
          this.type = this.routeParams.type;
        }

        if (typeof this.routeParams.assessment != "undefined") {
          this.assessment = this.routeParams.assessment;
        }

        if (typeof this.routeParams.cate != "undefined") {
          this.cate = this.routeParams.cate;
        }

        if (typeof this.routeParams.question != "undefined") {
          this.question = this.routeParams.question;
        }

        if (
          this.status == "edit" &&
          typeof this.routeParams.assessment != "undefined" &&
          typeof this.routeParams.cate != "undefined" &&
          typeof this.routeParams.question != "undefined"
        ) {
          this.loading = true;
          if (this.type == "answer") {
            this.fetchAnswerByData();
          } else {
            this.fetchSuggestionByData();
          }
        }
      }
    },
    fetchAnswerByData() {
      this.resetEditor = false;
      let obj = {
        assessmentId: this.routeParams.assessment,
        cateId: this.routeParams.cate,
        questionId: this.routeParams.question
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("get/choice", obj)
        .then(res => {
          if (res.data.success) {
            this.form.assessmentId = Number(res.data.obj.assessmentId);
            this.form.cateId = Number(res.data.obj.cateId);
            this.form.questionId = Number(res.data.obj.questionId);
            this.expane1 = res.data.obj.choices[0].description;
            this.expane2 = res.data.obj.choices[1].description;
            this.expane3 = res.data.obj.choices[2].description;
            this.expane4 = res.data.obj.choices[3].description;
            this.qAndC = res.data.obj.qAndC;
            this.choices = res.data.obj.choices;
            setTimeout(() => {
              this.loading = false;
            }, 250);
            this.resetEditor = true;
          } else {
            this.alertFailError();
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 10000);
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("createAnswer error", e);
        });
    },
    createAnswer() {
      if (this.haveDataTextHtml()) {
        this.loading = true;

        // let htmlExpane1 = this.$refs.tuiEditorExpane1.invoke("getHtml");
        // let htmlExpane2 = this.$refs.tuiEditorExpane2.invoke("getHtml");
        // let htmlExpane3 = this.$refs.tuiEditorExpane3.invoke("getHtml");
        // let htmlExpane4 = this.$refs.tuiEditorExpane4.invoke("getHtml");
        let htmlExpane1 = this.expane1;
        let htmlExpane2 = this.expane2;
        let htmlExpane3 = this.expane3;
        let htmlExpane4 = this.expane4;

        let obj = {
          _csrf: this.$csrfToken,
          assessmentId: this.form.assessmentId,
          cateId: this.form.cateId,
          questionId: this.form.questionId,
          choices: [
            {
              name: this.type == "suggestion" ? htmlShort1 : "ระดับ 1",
              level: 1,
              description: htmlExpane1
            },
            {
              name: this.type == "suggestion" ? htmlShort2 : "ระดับ 2",
              level: 2,
              description: htmlExpane2
            },
            {
              name: this.type == "suggestion" ? htmlShort3 : "ระดับ 3",
              level: 3,
              description: htmlExpane3
            },
            {
              name: this.type == "suggestion" ? htmlShort4 : "ระดับ 4",
              level: 4,
              description: htmlExpane4
            }
          ]
        };
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        HTTP.post("choices", obj)
          .then(res => {
            if (res.data.success) {
              this.form = {
                assessmentId: "",
                cateId: "",
                questionId: ""
              };
              this.resetEditor = false;
              setTimeout(() => {
                this.resetEditor = true;
              }, 250);
            } else {
              this.alertFailError();
            }
          })
          .catch(e => {
            if (e == "Error: Request failed with status code 401") {
              this.checkAuth();
            } else {
              if (e != "Error: timeout of 120000ms exceeded") {
                this.alertCatchError(e);
              }
            }
            console.log("createAnswer error", e);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 250);
          });
      }
    },
    updateAnswer() {
      if (this.haveDataTextHtml()) {
        this.loading = true;

        // let htmlExpane1 = this.$refs.tuiEditorExpane1.invoke("getHtml");
        // let htmlExpane2 = this.$refs.tuiEditorExpane2.invoke("getHtml");
        // let htmlExpane3 = this.$refs.tuiEditorExpane3.invoke("getHtml");
        // let htmlExpane4 = this.$refs.tuiEditorExpane4.invoke("getHtml");
        let htmlExpane1 = this.expane1;
        let htmlExpane2 = this.expane2;
        let htmlExpane3 = this.expane3;
        let htmlExpane4 = this.expane4;

        let obj = {
          _csrf: this.$csrfToken,
          qAndC: this.qAndC,
          assessmentId: this.form.assessmentId,
          cateId: this.form.cateId,
          questionId: this.form.questionId,
          choices: [
            {
              id: this.choices[0].id,
              name: "ระดับ 1",
              level: 1,
              description: htmlExpane1
            },
            {
              id: this.choices[1].id,
              name: "ระดับ 2",
              level: 2,
              description: htmlExpane2
            },
            {
              id: this.choices[2].id,
              name: "ระดับ 3",
              level: 3,
              description: htmlExpane3
            },
            {
              id: this.choices[3].id,
              name: "ระดับ 4",
              level: 4,
              description: htmlExpane4
            }
          ]
        };
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        HTTP.put("choice", obj)
          .then(res => {
            if (res.data.success) {
              let filterCate = "";
              let filterquest = "";
              let filterSize = "";
              if (this.routeParams.filercate) {
                filterCate = `&filercate=${this.routeParams.filercate}`;
              }
              if (this.routeParams.filerquest) {
                filterquest = `&filerquest=${this.routeParams.filerquest}`;
              }
              if (this.routeParams.size) {
                filterSize = `&size=${this.routeParams.size}`;
              }
              this.$message({
                message: "แก้ไขข้อมูลสำเร็จ",
                type: "success"
              });
              this.$router
                .push(
                  "/list/answer?type=answer" +
                    filterCate +
                    filterquest +
                    filterSize
                )
                .catch(() => {});
              this.resetEditor = false;
              setTimeout(() => {
                this.resetEditor = true;
              }, 250);
            } else {
              this.alertFailError();
            }
          })
          .catch(e => {
            if (e == "Error: Request failed with status code 401") {
              this.checkAuth();
            } else {
              if (e != "Error: timeout of 120000ms exceeded") {
                this.alertCatchError(e);
              }
            }
            console.log("createAnswer error", e);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 250);
          });
      } else {
        this.$message({
          type: "danger",
          message: `กรุณากรอกข้อมูลให้ครบ`
        });
      }
    },
    openDailogAddon(typeAddon, num, data, index, statusEdit) {
      this.formAddOn = {
        name: "",
        url: ""
      };

      if (typeof data != "undefined") {
        this.wantCreate = false;
        if (typeof statusEdit == "undefined") {
          this.formAddOn = {
            name: data.name,
            url: data.url
          };
          this.status = "create";
        } else {
          this.formAddOn = {
            id: data.id,
            name: data.name,
            url: data.url
          };
          this.status = "edit";
        }
        this.indexAddon = index;
      } else {
        this.status = "create";
        this.wantCreate = true;
      }
      this.numAddOn = num;
      this.typeAddon = typeAddon;
      this.dialogFormAddOn = true;
    },
    haveDataTextHtml() {
      var status = false;
      this.$refs["form"].validate(valid => {
        if (valid) {
          let allowShort = true;
          if (this.type == "suggestion") {
            // let htmlShort1 = this.$refs.tuiEditorShort1.invoke("getHtml");
            // let htmlShort2 = this.$refs.tuiEditorShort2.invoke("getHtml");
            // let htmlShort3 = this.$refs.tuiEditorShort3.invoke("getHtml");
            // let htmlShort4 = this.$refs.tuiEditorShort4.invoke("getHtml");
            let htmlShort1 = this.short1;
            let htmlShort2 = this.short2;
            let htmlShort3 = this.short3;
            let htmlShort4 = this.short4;
            if (htmlShort1 && htmlShort2 && htmlShort3 && htmlShort4) {
              allowShort = true;
            } else {
              allowShort = false;
            }
          }

          // let htmlExpane1 = this.$refs.tuiEditorExpane1.invoke("getHtml");
          // let htmlExpane2 = this.$refs.tuiEditorExpane2.invoke("getHtml");
          // let htmlExpane3 = this.$refs.tuiEditorExpane3.invoke("getHtml");
          // let htmlExpane4 = this.$refs.tuiEditorExpane4.invoke("getHtml");
          let htmlExpane1 = this.expane1;
          let htmlExpane2 = this.expane2;
          let htmlExpane3 = this.expane3;
          let htmlExpane4 = this.expane4;

          if (
            allowShort &&
            htmlExpane1 &&
            htmlExpane2 &&
            htmlExpane3 &&
            htmlExpane4
          ) {
            status = true;
          } else {
            this.$message({
              type: "danger",
              message: `กรุณากรอกข้อมูลให้ครบ`
            });
            status = false;
          }
        }
      });

      return status;
    },
    fetchSuggestionByData() {
      this.resetEditor = false;
      let obj = {
        assessmentId: this.routeParams.assessment,
        cateId: this.routeParams.cate,
        questionId: this.routeParams.question
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("get/recommend", obj)
        .then(res => {
          if (res.data.success) {
            this.form.assessmentId = Number(res.data.obj.assessmentId);
            this.form.cateId = Number(res.data.obj.cateId);
            this.form.questionId = Number(res.data.obj.questionId);

            this.qAndC = res.data.obj.qAndC;

            res.data.obj.recommend.map((item, index) => {
              this[`expane${index + 1}`] = item.extendDescription;
              this[`short${index + 1}`] = item.description;
              this.tool[`item${index + 1}`] = item.tools;
              this.course[`item${index + 1}`] = item.course;
            });

            this.recommend = JSON.parse(JSON.stringify(res.data.obj.recommend));
            setTimeout(() => {
              this.loading = false;
            }, 250);
            this.resetEditor = true;
          } else {
            this.alertFailError();
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 10000);
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("createAnswer error", e);
        });
    },
    createSuggestion() {
      if (this.haveDataTextHtml()) {
        this.loading = true;

        // let htmlShort1 = this.$refs.tuiEditorShort1.invoke("getHtml");
        // let htmlShort2 = this.$refs.tuiEditorShort2.invoke("getHtml");
        // let htmlShort3 = this.$refs.tuiEditorShort3.invoke("getHtml");
        // let htmlShort4 = this.$refs.tuiEditorShort4.invoke("getHtml");
        let htmlShort1 = this.short1;
        let htmlShort2 = this.short2;
        let htmlShort3 = this.short3;
        let htmlShort4 = this.short4;

        // let htmlExpane1 = this.$refs.tuiEditorExpane1.invoke("getHtml");
        // let htmlExpane2 = this.$refs.tuiEditorExpane2.invoke("getHtml");
        // let htmlExpane3 = this.$refs.tuiEditorExpane3.invoke("getHtml");
        // let htmlExpane4 = this.$refs.tuiEditorExpane4.invoke("getHtml");
        let htmlExpane1 = this.expane1;
        let htmlExpane2 = this.expane2;
        let htmlExpane3 = this.expane3;
        let htmlExpane4 = this.expane4;

        let obj = {
          assessmentId: this.form.assessmentId,
          cateId: this.form.cateId,
          questionId: this.form.questionId,
          recommend: [
            {
              level: 1,
              description: htmlShort1,
              extendDescription: htmlExpane1,
              tools: this.tool.item1,
              course: this.course.item1
            },
            {
              level: 2,
              description: htmlShort2,
              extendDescription: htmlExpane2,
              tools: this.tool.item2,
              course: this.course.item2
            },
            {
              level: 3,
              description: htmlShort3,
              extendDescription: htmlExpane3,
              tools: this.tool.item3,
              course: this.course.item3
            },
            {
              level: 4,
              description: htmlShort4,
              extendDescription: htmlExpane4,
              tools: this.tool.item4,
              course: this.course.item4
            }
          ]
        };
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        HTTP.post("recommend", obj)
          .then(res => {
            if (res.data.success) {
              this.form = {
                assessmentId: "",
                cateId: "",
                questionId: ""
              };
              this.resetEditor = false;
              setTimeout(() => {
                this.resetEditor = true;
              }, 250);
            } else {
              this.alertFailError();
            }
          })
          .catch(e => {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
            console.log("createAnswer error", e);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 250);
          });
      }
    },
    updateSuggestion() {
      if (this.haveDataTextHtml()) {
        this.loading = true;

        // let htmlShort1 = this.$refs.tuiEditorShort1.invoke("getHtml");
        // let htmlShort2 = this.$refs.tuiEditorShort2.invoke("getHtml");
        // let htmlShort3 = this.$refs.tuiEditorShort3.invoke("getHtml");
        // let htmlShort4 = this.$refs.tuiEditorShort4.invoke("getHtml");
        let htmlShort1 = this.short1;
        let htmlShort2 = this.short2;
        let htmlShort3 = this.short3;
        let htmlShort4 = this.short4;

        // let htmlExpane1 = this.$refs.tuiEditorExpane1.invoke("getHtml");
        // let htmlExpane2 = this.$refs.tuiEditorExpane2.invoke("getHtml");
        // let htmlExpane3 = this.$refs.tuiEditorExpane3.invoke("getHtml");
        // let htmlExpane4 = this.$refs.tuiEditorExpane4.invoke("getHtml");
        let htmlExpane1 = this.expane1;
        let htmlExpane2 = this.expane2;
        let htmlExpane3 = this.expane3;
        let htmlExpane4 = this.expane4;

        // this.tool=
        let delTools = {};
        let delCourse = {};
        let tool = {};
        let course = {};
        let addTools = {};
        let editTools = {};
        let editCourse = {};
        let addCourse = {};
        this.recommend.map((item, index) => {
          delTools[`item${index + 1}`] = [];
          delCourse[`item${index + 1}`] = [];
          tool[`item${index + 1}`] = [];
          addTools[`item${index + 1}`] = [];
          editTools[`item${index + 1}`] = [];
          editCourse[`item${index + 1}`] = [];
          course[`item${index + 1}`] = [];
          addCourse[`item${index + 1}`] = [];

          item.tools.map((tool, tIndex) => {
            let filterDelTool = this.tool[`item${index + 1}`].filter(
              filter => filter.id == tool.id
            );

            if (filterDelTool.length == 0) {
              delTools[`item${index + 1}`].push(tool.id);
            }
          });

          item.course.map((course, cIndex) => {
            let filterDelCourse = this.course[`item${index + 1}`].filter(
              filter => filter.id == course.id
            );

            if (filterDelCourse.length == 0) {
              delCourse[`item${index + 1}`].push(course.id);
            }
          });

          let filterTool = this.tool[`item${index + 1}`].filter(
            filter => typeof filter.id != "undefined"
          );

          tool[`item${index + 1}`] = filterTool;

          let filterToolAdd = this.tool[`item${index + 1}`].filter(
            filter => typeof filter.id == "undefined"
          );

          addTools[`item${index + 1}`] = filterToolAdd;

          let filterToolEdit = this.editTool[`item${index + 1}`].filter(
            filter => typeof filter.id != "undefined"
          );

          editTools[`item${index + 1}`] = filterToolEdit;

          let filterCourse = this.course[`item${index + 1}`].filter(
            filter => typeof filter.id != "undefined"
          );

          course[`item${index + 1}`] = filterCourse;
          let filterCourseEdit = this.editCourse[`item${index + 1}`].filter(
            filter => typeof filter.id != "undefined"
          );

          editCourse[`item${index + 1}`] = filterCourseEdit;

          let filterCourseAdd = this.course[`item${index + 1}`].filter(
            filter => typeof filter.id == "undefined"
          );

          addCourse[`item${index + 1}`] = filterCourseAdd;
        });

        let obj = {
          qAndC: this.qAndC,
          assessmentId: this.form.assessmentId,
          cateId: this.form.cateId,
          questionId: this.form.questionId,
          recommend: [
            {
              recommendId: this.recommend[0].recommendId,
              level: 1,
              description: htmlShort1,
              extendDescription: htmlExpane1,
              tools: tool.item1,
              course: course.item1,
              delTools: delTools.item1,
              delCourse: delCourse.item1,
              addTools: addTools.item1,
              editTools: editTools.item1,
              addCourse: addCourse.item1,
              editCourse: editCourse.item1
            },
            {
              recommendId: this.recommend[1].recommendId,
              level: 2,
              description: htmlShort2,
              extendDescription: htmlExpane2,
              tools: tool.item2,
              course: course.item2,
              delTools: delTools.item2,
              delCourse: delCourse.item2,
              addTools: addTools.item2,
              editTools: editTools.item2,
              addCourse: addCourse.item2,
              editCourse: editCourse.item2
            },
            {
              recommendId: this.recommend[2].recommendId,
              level: 3,
              description: htmlShort3,
              extendDescription: htmlExpane3,
              tools: tool.item3,
              course: course.item3,
              delTools: delTools.item3,
              delCourse: delCourse.item3,
              addTools: addTools.item3,
              editTools: editTools.item3,
              addCourse: addCourse.item3,
              editCourse: editCourse.item3
            },
            {
              recommendId: this.recommend[3].recommendId,
              level: 4,
              description: htmlShort4,
              extendDescription: htmlExpane4,
              tools: tool.item4,
              course: course.item4,
              delTools: delTools.item4,
              delCourse: delCourse.item4,
              addTools: addTools.item4,
              editTools: editTools.item4,
              addCourse: addCourse.item4,
              editCourse: editCourse.item4
            }
          ]
        };
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        HTTP.put("reccommend", obj)
          .then(res => {
            // console.log("updateSuggestion", res, obj);
            if (res.data.success) {
              let filterCate = "";
              let filterquest = "";
              let filterSize = "";
              if (this.routeParams.filercate) {
                filterCate = `&filercate=${this.routeParams.filercate}`;
              }
              if (this.routeParams.filerquest) {
                filterquest = `&filerquest=${this.routeParams.filerquest}`;
              }
              if (this.routeParams.size) {
                filterSize = `&size=${this.routeParams.size}`;
              }
              this.$message({
                message: "แก้ไขข้อมูลสำเร็จ",
                type: "success"
              });
              this.$router
                .push(
                  "/list/answer?type=suggestion" +
                    filterCate +
                    filterquest +
                    filterSize
                )
                .catch(() => {});
              this.resetEditor = false;
              setTimeout(() => {
                this.resetEditor = true;
              }, 250);
            } else {
              this.alertFailError();
            }
          })
          .catch(e => {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
            console.log("createAnswer error", e);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 250);
          });
      } else {
        this.$message({
          type: "danger",
          message: `กรุณากรอกข้อมูลให้ครบ`
        });
      }
    },
    createAddon() {
      if (this.typeAddon == "course") {
        this.course[`item${this.numAddOn}`].push(this.formAddOn);
      } else {
        this.tool[`item${this.numAddOn}`].push(this.formAddOn);
      }
      this.status = "";
      this.dialogFormAddOn = false;
    },
    updateAddon() {
      if (this.typeAddon == "course") {
        this.editCourse[`item${this.numAddOn}`][
          this.indexAddon
        ] = this.formAddOn;
        this.course[`item${this.numAddOn}`][this.indexAddon] = this.formAddOn;
      } else {
        this.editTool[`item${this.numAddOn}`][this.indexAddon] = this.formAddOn;
        this.tool[`item${this.numAddOn}`][this.indexAddon] = this.formAddOn;
      }
      // console.log(this.editTool);
      this.dialogFormAddOn = false;
    },
    delAddon(index, num, type) {
      if (type == "course") {
        this.course[`item${num}`].splice(index, 1);
      } else {
        this.tool[`item${num}`].splice(index, 1);
      }
    }
  }
};
</script>